<template>
  <v-row v-if="product" class="product-detail" :class="[promoClass]">
    <v-col cols="12" sm="6" class="py-0 py-sm-3 img-col">
      <div class="promo-wrapper promo-wrapper-product-card">
        <v-chip
          v-if="product.newProduct"
          class="new text-uppercase black--text"
          color="accent"
        >
          {{ $t("filter.new") }}
        </v-chip>

        <product-promo
          v-if="product.warehousePromo"
          :warehousePromo="product.warehousePromo"
        />
        <div
          v-if="
            product.warehousePromo &&
              product.warehousePromo.view &&
              product.warehousePromo.view.bubble
          "
          class="promo_bubble"
          v-html="product.warehousePromo.view.bubble"
        ></div>
        <product-gift-certificate
          v-for="userGiftCertificate in product.userGiftCertificates"
          :key="userGiftCertificate.giftCertificate.giftCertificateId"
          :giftCertificate="userGiftCertificate.giftCertificate"
          :containerRef="$refs.productCard"
          @add="giftAdded"
        />
      </div>
      <v-card flat v-if="selectedImage">
        <ProductImages
          :images="product.media"
          :name="product.name"
          :key="product.productId"
        />
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      class="py-0 pb-3 py-sm-3 default--text"
      style="position:relative"
      ><div class="d-flex align-center">
        <h2 class="font-weight-regular mt-0 mb-1">
          {{ product.name }}
        </h2>
      </div>
      <span class="short_descr">
        {{ product.shortDescr }}
      </span>
      <div class="my-2">
        <span class="descr" v-if="product.description">
          {{ product.description }}</span
        >
        <span
          v-if="
            product.productInfos?.TIPOLOGIA != 'Sfuso' && product.priceUmDisplay
          "
          class="weight-unit"
        >
          <div
            v-if="
              product.priceStandardUmDisplay &&
                product.priceStandardUmDisplay != product.priceUmDisplay
            "
          >
            <div style="text-decoration: line-through;">
              {{ $n(product.priceStandardUmDisplay, "currency") }}/{{
                product.weightUnitDisplay
              }}
            </div>
          </div>

          {{ $n(product.priceUmDisplay, "currency") }}/{{
            product.weightUnitDisplay
          }}
        </span>
        <span
          v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
          class="weight-unit descr"
        >
          {{ minQuantity }} {{ unit }}
        </span>
      </div>

      <div class="code">
        <v-chip label>
          {{ product.codInt }}
        </v-chip>
      </div>
      <div
        class="characteristics"
        v-if="productClasses && productClasses.length > 0"
      >
        <div class="d-flex flex-wrap product-class-wraper mt-1">
          <ProductClass
            :pClass="pClass"
            :width="34"
            :height="34"
            v-for="pClass in productClasses"
            :key="pClass.productClassId"
          />
        </div>
      </div>
      <ProductAcqLimit :product="product" />
      <div
        class="option-wrapper mt-3"
        v-for="selectOption in selectOptionsArray"
        :key="selectOption.name"
        @click.stop.prevent
        @mousedown.stop
      >
        <v-select
          outlined
          dense
          v-model="selectedOptions[selectOption.parameterName]"
          :items="selectOption.options"
          :label="selectOption.name"
          item-text="key"
          item-value="value"
          @change="optionChanged"
        />
      </div>

      <div class="price-qty-wraper d-flex justify-space-between mt-3">
        <ProductPrice
          v-if="product.priceDisplay"
          :product="product"
        ></ProductPrice>
        <product-lead-time
          v-if="product.productInfos && product.productInfos.LEAD_TIME"
          :time="product.productInfos.LEAD_TIME"
          :showTooltip="true"
        />
        <v-spacer></v-spacer>
        <DayLock
          v-if="product.dayLock"
          :days="product.dayLock"
          :showTooltip="true"
          class="mr-1"
        />
        <ProductQty
          v-if="product.available > 0"
          class="mt-3"
          :selectedOptions="selectedOptions"
          :product="product"
          :showAddToCartLabel="true"
          :isProductDetail="true"
          :label="
            $vuetify.breakpoint.smAndDown ? null : $t('products.addToCart')
          "
        />

        <div
          v-else-if="product.available == 0"
          class="product-not-available align-self-center"
        >
          <!-- Lockers: 
          3 ; Liguria
          7 ; Lombardia
          11 ; Novacoop -->
          <span
            v-if="
              (cart.shippingAddress.deliveryServiceId === 3 ||
                cart.shippingAddress.deliveryServiceId === 7 ||
                cart.shippingAddress.deliveryServiceId === 11) &&
                product.productInfos &&
                product.productInfos.LOCKER_LEVEL
            "
            >{{ $t("product.notAvailableForLocker") }}</span
          >
          <span v-else-if="product.dayLock">{{ $t("product.dayLock") }}</span>
          <span v-else>{{ $t("product.notAvailable") }}</span>
        </div>
      </div>
      <v-btn
        large
        depressed
        @click.stop.prevent="addToFavorites"
        @mousedown.stop
        color="white"
        class="font-weight-regular text-none mt-1"
      >
        <transition name="pulse">
          <v-icon color="primary" :key="highlight" left>
            {{ highlight ? "$heartfull" : "$heart" }}
          </v-icon> </transition
        >{{ $t("product.addToList") }}
      </v-btn>
      <v-divider class="my-2" />
    </v-col>
  </v-row>
</template>
<style lang="scss">
.product-detail {
  span.point::before {
    content: " \25CF";
    font-size: 20px;
    line-height: 12px;
    color: #ed7c20;
  }
  .short_descr {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .weight-unit.descr,
  .descr {
    border: 1px solid var(--v-default-base);
    border-radius: $border-radius-root;
    padding: 2px 3px;
  }

  .weight-unit {
    font-size: 16px;
  }
  .product-price {
    width: auto;
    .product_promo {
      position: relative;
      & > .card {
        bottom: 44px;
      }
    }
  }
  .promo_bubble {
    left: 80px;
    margin-top: 2px;
  }
  .vendorIcon {
    position: absolute;
    right: 6px;
    top: -18px;
    z-index: 6;
  }

  .price-um {
    font-size: 12px !important;
    margin-top: 5px !important;
  }
  .add-to-list-text.text-body-2 {
    font-size: 16px !important;
  }
  .description {
    font-weight: normal;
    font-size: 16px;
  }
  .code {
    .v-chip {
      font-size: 12px;
      height: 23px;
      background-color: #f5f5f5;
    }
  }
  .characteristics {
    span {
      font-size: 16px;
    }
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .product-class-wraper {
    column-gap: 3px;
    row-gap: 5px;
    .product-class {
      img {
        height: 30px;
      }
    }
  }
  &-information {
    &-table tr {
      td:nth-child(1) {
        font-weight: bold;
      }
      td:nth-child(2) {
        padding-left: 10px;
      }
    }
  }
  .img-col {
    position: relative;
    .promo-wrapper {
      left: 4px;
      top: 20px;
      z-index: 4;
    }
  }
  .price-qty-wraper {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 0px 12px;
    min-height: 44px;
  }
  .max-acq {
    position: unset;
    display: block;
    width: fit-content;
    margin-top: 10px;
  }
  .promo-wrapper.promo-wrapper-product-card {
    flex-direction: column;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
// import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductQty from "./ProductQty.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductGiftCertificate from "../gift/ProductGiftCertificate.vue";
import DayLock from "./ProductDayLock.vue";
import ProductPromo from "./ProductPromo.vue";
import ProductLeadTime from "./ProductLeadTime.vue";

// import ProductRating from "@/components/product/ProductRating.vue";
// import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";

import clickHandler from "~/mixins/clickHandler";
import productMixin from "~/mixins/product";
import downloadDocument from "~/mixins/downloadDocument";

import get from "lodash/get";
import { mapState, mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";

import cartService from "~/service/cartService";

export default {
  name: "ProductDetail",
  components: {
    ProductPrice,
    // ProductPromo,
    ProductClass,
    ProductImages,
    // ProductGiftCertificate,
    ProductQty,
    ProductAcqLimit,
    ProductGiftCertificate,
    ProductPromo,
    ProductLeadTime,
    DayLock
    // ProductRating
    // ProductCustomerVotes
  },
  mixins: [productMixin, clickHandler, downloadDocument],
  props: ["selectedImage", "product"],
  data() {
    return {
      imageDetailDialog: false,
      selectedOptions: {},
      selectOptionsArray: []
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.newProduct) {
        productClasses.push("new-product");
      }
      // if (this.product.edge) {
      //   productClasses.push(this.product.badge.cssClass);
      // }
      return productClasses;
    },
    minQuantity() {
      return cartService.plus(this.product);
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    currentPageUrl() {
      return window.location.href;
    }
  },
  methods: {
    downloadDocument(productId) {
      var url = window.event.currentTarget.href;
      this.handleReceiptDownload(url, productId);
    },
    goToProductCustomerVote() {
      // this.$vuetify.goTo(this.$refs.disclaimerform);
      this.$emit("goToCustomerVote", true);
    },
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    setOptionsArray() {
      if (this.product.selectOptions && this.product.selectOptions.length) {
        this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      } else {
        this.selectOptionsArray = [];
      }
    }
  },
  created() {
    this.setOptionsArray();
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        this.setOptionsArray();
      }
    }
  }
};
</script>
